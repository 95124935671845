import { useHistory } from "react-router-dom";
export let orgId: string = "";
export const useVerifyCred = () => {
    const history = useHistory();
    let urlParams = window.location.href;
    let params = urlParams.split("/");
    console.log(`url in provider:`, params);
    let tag: string = "";
    if (params?.length >= 6) {
        tag =
            params && Array.isArray(params) && params.length > 2
                ? params[params?.length - 1]
                : "";
        orgId =
            params && Array.isArray(params) && params.length > 2
                ? params[params?.length - 2]
                : "";
        if (tag) {
            tag = String(tag).trim();
        }
        if (orgId) {
            orgId = String(orgId).trim();
        }
        return {
            tag,
            orgId,
        };
    } else {
        history.push(`/`);
    }
    // const urlParams = new URLSearchParams(window.location.search);
    // let id = urlParams.get("id");
    // if (id) {
    //     id = String(id).trim();
    // }
    // return {
    //     id,
    // };
};
